<template>
  <lf-card class="max-w-screen-md" data-cy="scoreCardTab">
    <loader :isLoading="isSubmitting" />
    <form @submit.prevent="submit">
      <div class="pb-2 pt-8 space-y-5">
        <lf-switch
          name="data.can_manage_score_cards"
          v-model="initialValues.data.can_manage_score_cards"
        >
          <lf-h3>
            {{ $t("ORGANIZATION.ENABLE_SCORECARDS") }}
          </lf-h3>
          <span class="mt-2">
            {{ $t("ORGANIZATION.ENABLE_SCORECARDS_SUBTITLE") }}
          </span>
        </lf-switch>
      </div>
      <div
        class="flex justify-end items-center min-w-full border-t py-6 pr-5 mt-5"
      >
        <primary-button
          type="submit"
          :disabled="isSubmitting"
          data-cy="scoreCardTabSubmit"
        >
          {{ $t("ORGANIZATION.API_CONFIG.SAVE_CHANGES") }}
        </primary-button>
      </div>
    </form>
  </lf-card>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";
import { useClients } from "@/hooks/clients";

const { activeClient } = useClients();

const initialValues = reactive({
  data: {
    can_manage_score_cards: !!activeClient.value?.can_manage_score_cards
  }
});

const { handleSubmit, isSubmitting } = useForm({
  initialValues
});

const submit = handleSubmit(async (values, actions) => {
  await dispatchAction(values, actions, "clients/updateClient");
});
</script>
